import axios from 'axios'
import { useSelector } from 'react-redux'
import { logout } from 'src/features/auth/authSlice'
import { getAuthToken } from 'src/hooks/useLocalStorage'
import { RootState, store } from 'src/store/reducers/store'

const baseUrl = 'https://cms.vennclub.com'

const CustomAxios = axios.create({
    baseURL: baseUrl,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Cache-Control': 'no-cache',
        'X-Requested-With': 'XMLHttpRequest'
    }
})

export const toCamelCase: any = (object: any) => {
    let transformedObject = object
    if (typeof object === 'object' && object !== null) {
        if (object instanceof Array) {
            transformedObject = object.map(toCamelCase)
        } else {
            transformedObject = {}
            for (const key in object) {
                if (object[key] !== undefined) {
                    const newKey = key.replace(/(_\w)|(-\w)/g, (k) => k[1].toUpperCase())
                    transformedObject[newKey] = toCamelCase(object[key])
                }
            }
        }
    }
    return transformedObject
}

export const toSnackCase: any = (object: any) => {
    let transformedObject = object
    if (typeof object === 'object' && object !== null) {
        if (object instanceof Array) {
            transformedObject = object.map(toSnackCase)
        } else {
            transformedObject = {}
            for (const key in object) {
                if (object[key] !== undefined) {
                    const newKey = key
                        .replace(/\.?([A-Z]+)/g, function (_, y) {
                            return '_' + y.toLowerCase()
                        })
                        .replace(/^_/, '')
                    transformedObject[newKey] = toSnackCase(object[key])
                }
            }
        }
    }
    return transformedObject
}

CustomAxios.interceptors.response.use(
    (response) => {
        // Skip transformation for multipart/form-data responses
        const contentType = response.headers['content-type'];
        if (contentType && contentType.includes('multipart/form-data')) {
            return response;
        }

        response.data = toCamelCase(response.data)
        return response
    },
    (error) => {
        return Promise.reject(error)
    }
)

CustomAxios.interceptors.request.use(
    (config) => {
        const headers = config.headers as Record<string, string | undefined>;
        const access_token = localStorage.getItem('access_token');
        if (access_token) {
            config.headers.Authorization = 'Bearer ' + access_token;
        }
        // Skip transformation for multipart/form-data requests
        if (headers['Content-Type'] && headers['Content-Type'].includes('multipart/form-data')) {
            return config;
        }

        config.data = toSnackCase(config.data)
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)


// CustomAxios.interceptors.request.use(config => {
//     const state: RootState = store.getState();
//     const userToken = state.user.userToken; // Example: Access auth token from state
//     console.log('Intercepting request...');
//     if (userToken) {
//         config.headers.Authorization = `Bearer ${userToken}`;
//         console.log('Authorization header set:', config.headers.Authorization);
//     }
//     return config;
// }, error => {
//     // Handle errors
//     console.error('Request interceptor error:', error);
//     return Promise.reject(error);
// });
// CustomAxios.interceptors.response.use(
//     response => response,
//     error => {
//         // Handle errors
//         console.log(error)
//         if (error.response.status == 401) {
//             store.dispatch(logout());
//         }
//         return Promise.reject(error);
//     }
// );

// CustomAxios.interceptors.request.use(config => {
//     const token = getAuthToken(); // Function to get the current token
//     if (token) {
//         config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
// });

export default CustomAxios
