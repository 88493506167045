import React, { Dispatch, forwardRef, SetStateAction, useEffect, useImperativeHandle, useRef } from "react";
import {
    Input,
    Popover,
    PopoverHandler,
    PopoverContent,
} from "@material-tailwind/react";
import { format } from "date-fns";
import { DayButtonProps, DayPicker } from "react-day-picker";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";
import { Editor, IAllProps } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditorTypes } from "tinymce";


interface PropType {
    value: string | undefined,
}

// Define the ref type
export type TinyMCEHandle = {
    getContent: () => string | undefined; // Example method that can be invoked from parent
};

// const TinyMCE = forwardRef<TinyMCEHandle, PropType>(() =>{ })

const TinyMCE = forwardRef<TinyMCEHandle, PropType>(({
    value
}, ref) => {

    const editorRef = useRef<TinyMCEEditorTypes>();

    useImperativeHandle(ref, () => ({
        getContent: () => {
            if (editorRef.current) {
                const tinymce = editorRef.current;
                console.log(tinymce.getContent()); // Assuming getContent() is a method on TinyMCEEditor
                return tinymce.getContent()
            }
        }
    }));


    return (
        <>
            <Editor
                apiKey='i6lxt9hzm2a8uthp60l5izzp7tbpd09mxfb2gqk1qgh4vjy5'
                init={{
                    menubar: '',
                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | outdent indent',
                }}
                onInit={(_evt, editor) => editorRef.current = editor}
                initialValue={value}
            />
        </>
    );
});

TinyMCE.displayName = 'TinyMCE';

export default TinyMCE;
