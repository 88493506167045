import { AsyncThunkPayloadCreatorReturnValue, createAsyncThunk } from "@reduxjs/toolkit"
import CustomAxios from "@utility/customAxios"
import { handleAxiosError } from "@utility/errorUtils";
import { Events, InsertEventPayload, PutEventPayload, DeleteEventPayload } from "./eventTypes";
import { ApiResponse } from "../Types";

export interface InsertEventType {
    ReturnType: ApiResponse;
    FirstArgumentType: InsertEventPayload;
    ThunkAPIType: { rejectValue: string };
}

export const insertEvent = createAsyncThunk<
    InsertEventType['ReturnType'],
    InsertEventType['FirstArgumentType'],
    InsertEventType['ThunkAPIType'] // { rejectValue: string }
>(
    'event/insert',
    async (formData: FormData, { rejectWithValue }) => {
        try {
            const { data } = await CustomAxios.post<ApiResponse>(
                `/api/event/store`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            )
            return data
        } catch (error: unknown) {
            const errorMessage = handleAxiosError(error);
            return rejectWithValue(errorMessage)
        }
    }
)

export interface PutEventType {
    ReturnType: ApiResponse;
    FirstArgumentType: PutEventPayload;
    ThunkAPIType: { rejectValue: string };
}

export const putEvent = createAsyncThunk<
    PutEventType['ReturnType'],
    PutEventType['FirstArgumentType'],
    PutEventType['ThunkAPIType'] // { rejectValue: string }
>(
    'event/edit',
    async ({
        id,
        formData
    }, { rejectWithValue }) => {
        try {
            const { data } = await CustomAxios.post<ApiResponse>(
                `/api/event/` + id,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            )
            return data
        } catch (error: unknown) {
            const errorMessage = handleAxiosError(error);
            return rejectWithValue(errorMessage)
        }
    }
)
export interface DeleteEventType {
    ReturnType: ApiResponse;
    FirstArgumentType: DeleteEventPayload;
    ThunkAPIType: { rejectValue: string };
}

export const deleteEvent = createAsyncThunk<
    DeleteEventType['ReturnType'],
    DeleteEventType['FirstArgumentType'],
    DeleteEventType['ThunkAPIType'] // { rejectValue: string }
>(
    'event/delete',
    async ({
        id
    }, { rejectWithValue }) => {
        try {
            const { data } = await CustomAxios.delete<ApiResponse>(
                `/api/event/` + id
            )
            return data
        } catch (error: unknown) {
            const errorMessage = handleAxiosError(error);
            return rejectWithValue(errorMessage)
        }
    }
)

export interface GetEventType {
    ReturnType: ApiResponse;
    FirstArgumentType: void;
    ThunkAPIType: { rejectValue: string };
}

export const getEvent = createAsyncThunk<
    GetEventType['ReturnType'],  // ApiResponse
    GetEventType['FirstArgumentType'], // void
    GetEventType['ThunkAPIType'] // { rejectValue: string }
>(
    'event/get',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await CustomAxios.get<ApiResponse>(
                `/api/event`
            )
            return data
        } catch (error: unknown) {
            const errorMessage = handleAxiosError(error);
            return rejectWithValue(errorMessage)
        }
    }
)


export const getEventLandingPage = createAsyncThunk<
    GetEventType['ReturnType'],  // ApiResponse
    GetEventType['FirstArgumentType'], // void
    GetEventType['ThunkAPIType'] // { rejectValue: string }
>(
    'event/get/landingpage',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await CustomAxios.get<ApiResponse>(
                `/api/data/event`
            )
            return data
        } catch (error: unknown) {
            const errorMessage = handleAxiosError(error);
            return rejectWithValue(errorMessage)
        }
    }
)