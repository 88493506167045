import { AsyncThunkPayloadCreatorReturnValue, createAsyncThunk } from "@reduxjs/toolkit"
import CustomAxios from "@utility/customAxios"
import { handleAxiosError } from "@utility/errorUtils";
import { Headlines, InsertHeadlinePayload, PutContentPayload, DeleteHeadlinePayload } from "./headlineTypes";
import axios from "axios";
import { ApiResponse } from "../Types";

export interface InsertHeadlineType {
    ReturnType: ApiResponse;
    FirstArgumentType: InsertHeadlinePayload;
    ThunkAPIType: { rejectValue: string };
}

export const insertHeadline = createAsyncThunk<
    InsertHeadlineType['ReturnType'],
    InsertHeadlineType['FirstArgumentType'],
    InsertHeadlineType['ThunkAPIType'] // { rejectValue: string }
>(
    'headline/insert',
    async (formData: FormData, { rejectWithValue }) => {
        try {
            const { data } = await CustomAxios.post<ApiResponse>(
                `/api/headline/store`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            )
            return data
        } catch (error: unknown) {
            const errorMessage = handleAxiosError(error);
            return rejectWithValue(errorMessage)
        }
    }
)

export interface PutHeadlineType {
    ReturnType: ApiResponse;
    FirstArgumentType: PutContentPayload;
    ThunkAPIType: { rejectValue: string };
}

export const putHeadline = createAsyncThunk<
    PutHeadlineType['ReturnType'],
    PutHeadlineType['FirstArgumentType'],
    PutHeadlineType['ThunkAPIType'] // { rejectValue: string }
>(
    'headline/edit',
    async ({
        id,
        formData
    }, { rejectWithValue }) => {
        try {
            const { data } = await CustomAxios.post<ApiResponse>(
                `/api/headline/` + id,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            )
            return data
        } catch (error: unknown) {
            const errorMessage = handleAxiosError(error);
            return rejectWithValue(errorMessage)
        }
    }
)
export interface DeleteHeadlineType {
    ReturnType: ApiResponse;
    FirstArgumentType: DeleteHeadlinePayload;
    ThunkAPIType: { rejectValue: string };
}

export const deleteHeadline = createAsyncThunk<
    DeleteHeadlineType['ReturnType'],
    DeleteHeadlineType['FirstArgumentType'],
    DeleteHeadlineType['ThunkAPIType'] // { rejectValue: string }
>(
    'headline/delete',
    async ({
        id
    }, { rejectWithValue }) => {
        try {
            const { data } = await CustomAxios.delete<ApiResponse>(
                `/api/headline/` + id
            )
            return data
        } catch (error: unknown) {
            const errorMessage = handleAxiosError(error);
            return rejectWithValue(errorMessage)
        }
    }
)

export interface GetHeadlineType {
    ReturnType: ApiResponse;
    FirstArgumentType: void;
    ThunkAPIType: { rejectValue: string };
}

export const getHeadline = createAsyncThunk<
    GetHeadlineType['ReturnType'],  // ApiResponse
    GetHeadlineType['FirstArgumentType'], // void
    GetHeadlineType['ThunkAPIType'] // { rejectValue: string }
>(
    'headline/get',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await CustomAxios.get<ApiResponse>(
                `/api/headline`
            )
            return data
        } catch (error: unknown) {
            const errorMessage = handleAxiosError(error);
            return rejectWithValue(errorMessage)
        }
    }
)

export const getHeadlineLandingPage = createAsyncThunk<
    GetHeadlineType['ReturnType'],  // ApiResponse
    GetHeadlineType['FirstArgumentType'], // void
    GetHeadlineType['ThunkAPIType'] // { rejectValue: string }
>(
    'headline/get/landingpage',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await CustomAxios.get<ApiResponse>(
                `/api/data/headline`
            )
            return data
        } catch (error: unknown) {
            const errorMessage = handleAxiosError(error);
            return rejectWithValue(errorMessage)
        }
    }
)