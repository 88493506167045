// features/auth/authSlice.js
import { createSlice } from '@reduxjs/toolkit'
import { userLogin } from './authAction'
import { LoginResponse } from './authTypes';

interface AuthState {
    loading: boolean;
    userInfo: LoginResponse | null; // Adjust as needed
    userToken: string | null;
    error: string | null; // Ensure this is string or null
    success: boolean;
}

// initialize userToken from local storage
const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null

const initialState: AuthState = {
    loading: false,
    userInfo: null, // or {} depending on your data structure
    userToken: null,
    error: null,
    success: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            localStorage.removeItem('access_token') // deletes token from storage
            state.loading = false
            state.userInfo = null
            state.error = null
        },
        setCredentials: (state, { payload }) => {
            state.userInfo = payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(userLogin.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(userLogin.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.userInfo = payload;
                state.success = true; // Optionally handle success
                localStorage.setItem('access_token', payload.accessToken)
            })
            .addCase(userLogin.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload as string; // Type assertion if payload is string
                state.success = false; // Optionally handle failure
            });
    },


})

export const { logout } = authSlice.actions
export default authSlice.reducer