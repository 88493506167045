export const ROUTES = {
    LOGIN_ROUTE: '/admin/login',
    HOMEPAGE_ROUTE: '/',
    RESERVATIONPAGE_ROUTE: '/reservation',
    ADMIN_ROUTE: '/admin',
    DASHBOARD_ROUTE: '/admin/dashboard',
    HEADLINE_ROUTE: '/admin/headline',
    DRINK_ROUTE: '/admin/drink',
    PROMO_ROUTE: '/admin/promo',
    EVENT_ROUTE: '/admin/event',
    AMBIENCE_ROUTE: '/admin/ambience',
}