// features/promo/promoSlice.js
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { deletePromo, getPromo, getPromoLandingPage, insertPromo, putPromo } from './promoAction';
import { ApiResponse } from '../Types';
import { Promo } from './promoTypes';

interface PromoState {
    loading: boolean;
    promos: Array<Promo> | []; // Adjust as needed
    error: string | null; // Ensure this is string or null
    success: boolean;
    showModal: boolean,
    refreshing: boolean,
    showModalDelete: boolean,
}

// initialize userToken from local storage
const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null

const initialState: PromoState = {
    loading: false,
    promos: [], // or {} depending on your data structure
    error: null,
    success: false,
    showModal: false,
    refreshing: false,
    showModalDelete: false,
};

const promoSlice = createSlice({
    name: 'promo',
    initialState,
    reducers: {
        getPromos: (state, { payload }) => {
            state.promos = payload
        },
        actionToggleModalPromo: (state) => {
            state.showModal = !state.showModal;
        },
        actionToggleModalPromoDelete: (state) => {
            state.showModalDelete = !state.showModalDelete;
        },
    },
    extraReducers: (builder) => {
        builder
            // Insert
            .addCase(insertPromo.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(insertPromo.fulfilled, (state, { payload }: PayloadAction<ApiResponse<Promo>>) => {
                state.loading = false;
                state.promos = [...state.promos];
                state.success = true; // Optionally handle success
                state.showModal = false;
                state.refreshing = true;
            })
            .addCase(insertPromo.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload as string; // Type assertion if payload is string
                state.success = false; // Optionally handle failure
            })
            // PUT
            .addCase(putPromo.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(putPromo.fulfilled, (state, { payload }: PayloadAction<ApiResponse<Promo>>) => {
                state.loading = false;
                state.promos = state.promos.map(promo => 
                    promo.id === payload.data.id ? payload.data : promo
                );
                state.success = true; // Optionally handle success
                state.showModal = false;
                state.refreshing = true;
            })
            .addCase(putPromo.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload as string; // Type assertion if payload is string
                state.success = false; // Optionally handle failure
            })
            // Delete
            .addCase(deletePromo.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deletePromo.fulfilled, (state, { payload }: PayloadAction<ApiResponse<Promo>>) => {
                state.loading = false;
                state.success = true; // Optionally handle success
                state.showModalDelete = false;
                state.refreshing = true;
            })
            .addCase(deletePromo.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload as string; // Type assertion if payload is string
                state.success = false; // Optionally handle failure
            })
            // Get
            .addCase(getPromo.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.refreshing = false;
            })
            .addCase(getPromo.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.promos = [...payload.data];
                state.success = true; // Optionally handle success
            })
            .addCase(getPromo.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload as string; // Type assertion if payload is string
                state.success = false; // Optionally handle failure
            })
            // Get
            .addCase(getPromoLandingPage.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.refreshing = false;
            })
            .addCase(getPromoLandingPage.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.promos = [...payload.data];
                state.success = true; // Optionally handle success
            })
            .addCase(getPromoLandingPage.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload as string; // Type assertion if payload is string
                state.success = false; // Optionally handle failure
            });
    },


})

export const { actionToggleModalPromo, actionToggleModalPromoDelete } = promoSlice.actions
export default promoSlice.reducer