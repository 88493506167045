import { AsyncThunkPayloadCreatorReturnValue, createAsyncThunk } from "@reduxjs/toolkit"
import CustomAxios from "@utility/customAxios"
import { handleAxiosError } from "@utility/errorUtils";
import { Promos, InsertPromoPayload, PutPromoPayload, DeletePromoPayload } from "./promoTypes";
import { ApiResponse } from "../Types";

export interface InsertPromoType {
    ReturnType: ApiResponse;
    FirstArgumentType: InsertPromoPayload;
    ThunkAPIType: { rejectValue: string };
}

export const insertPromo = createAsyncThunk<
    InsertPromoType['ReturnType'],
    InsertPromoType['FirstArgumentType'],
    InsertPromoType['ThunkAPIType'] // { rejectValue: string }
>(
    'promo/insert',
    async (formData: FormData, { rejectWithValue }) => {
        try {
            const { data } = await CustomAxios.post<ApiResponse>(
                `/api/promo/store`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            )
            return data
        } catch (error: unknown) {
            const errorMessage = handleAxiosError(error);
            return rejectWithValue(errorMessage)
        }
    }
)

export interface PutPromoType {
    ReturnType: ApiResponse;
    FirstArgumentType: PutPromoPayload;
    ThunkAPIType: { rejectValue: string };
}

export const putPromo = createAsyncThunk<
    PutPromoType['ReturnType'],
    PutPromoType['FirstArgumentType'],
    PutPromoType['ThunkAPIType'] // { rejectValue: string }
>(
    'promo/edit',
    async ({
        id,
        formData
    }, { rejectWithValue }) => {
        try {
            const { data } = await CustomAxios.post<ApiResponse>(
                `/api/promo/` + id,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            )
            return data
        } catch (error: unknown) {
            const errorMessage = handleAxiosError(error);
            return rejectWithValue(errorMessage)
        }
    }
)
export interface DeletePromoType {
    ReturnType: ApiResponse;
    FirstArgumentType: DeletePromoPayload;
    ThunkAPIType: { rejectValue: string };
}

export const deletePromo = createAsyncThunk<
    DeletePromoType['ReturnType'],
    DeletePromoType['FirstArgumentType'],
    DeletePromoType['ThunkAPIType'] // { rejectValue: string }
>(
    'promo/delete',
    async ({
        id
    }, { rejectWithValue }) => {
        try {
            const { data } = await CustomAxios.delete<ApiResponse>(
                `/api/promo/` + id
            )
            return data
        } catch (error: unknown) {
            const errorMessage = handleAxiosError(error);
            return rejectWithValue(errorMessage)
        }
    }
)

export interface GetPromoType {
    ReturnType: ApiResponse;
    FirstArgumentType: void;
    ThunkAPIType: { rejectValue: string };
}

export const getPromo = createAsyncThunk<
    GetPromoType['ReturnType'],  // ApiResponse
    GetPromoType['FirstArgumentType'], // void
    GetPromoType['ThunkAPIType'] // { rejectValue: string }
>(
    'promo/get',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await CustomAxios.get<ApiResponse>(
                `/api/promo`
            )
            return data
        } catch (error: unknown) {
            const errorMessage = handleAxiosError(error);
            return rejectWithValue(errorMessage)
        }
    }
)


export const getPromoLandingPage = createAsyncThunk<
    GetPromoType['ReturnType'],  // ApiResponse
    GetPromoType['FirstArgumentType'], // void
    GetPromoType['ThunkAPIType'] // { rejectValue: string }
>(
    'promo/get/landingpage',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await CustomAxios.get<ApiResponse>(
                `/api/data/promo`
            )
            return data
        } catch (error: unknown) {
            const errorMessage = handleAxiosError(error);
            return rejectWithValue(errorMessage)
        }
    }
)