import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the type for interceptors
type Interceptor = 'token' | 'logout'; // Adjust this type according to your needs

interface InterceptorState {
    interceptors: Interceptor[];
    isInterceptorSet: boolean;
}

const initialState: InterceptorState = {
    interceptors: [],
    isInterceptorSet: false,
};

const requiredInterceptors: Interceptor[] = ['token', 'logout'];


const interceptorSlice = createSlice({
    name: 'interceptor',
    initialState,
    reducers: {
        addInterceptor: (state, action: PayloadAction<Interceptor>) => {
            if (!state.interceptors.includes(action.payload)) {
                state.interceptors.push(action.payload);
            }

            // Check if all required interceptors are set
            state.isInterceptorSet = requiredInterceptors.every(interceptor => state.interceptors.includes(interceptor));
        },
        removeInterceptor: (state, action: PayloadAction<Interceptor>) => {
            state.interceptors = state.interceptors.filter(interceptor => interceptor !== action.payload);

            // Optionally reset isInterceptorSet if needed
            state.isInterceptorSet = requiredInterceptors.every(interceptor => state.interceptors.includes(interceptor));
        },
        resetInterceptors: (state) => {
            state.interceptors = [];
            state.isInterceptorSet = false;
        },
    },
});

export const { addInterceptor, removeInterceptor, resetInterceptors } = interceptorSlice.actions;
export default interceptorSlice.reducer;