
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers/store";
import ContentTable from "@components/table/ContentTable";


export default function EventPage() {
    const event = useSelector((state: RootState) => state.event)
    const { loading, error, events: data, showModal, refreshing, showModalDelete } = event;
    return (
        <ContentTable
            content="event"
            {...event}
            data={data}
        />
    );
}