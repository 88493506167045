
import React, { useEffect } from "react";
import ContentTable from "@components/table/ContentTable";
import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers/store";


export default function DrinkPage() {
    const drink = useSelector((state: RootState) => state.drink)
    const { loading, error, drinks: data, showModal, refreshing, showModalDelete } = drink;
    return (
        <ContentTable
            content="drink"
            {...drink}
            data={data}
        />
    );
}