
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers/store";
import ContentTable from "@components/table/ContentTable";


export default function PromoPage() {
    const promo = useSelector((state: RootState) => state.promo)
    const { loading, error, promos: data, showModal, refreshing, showModalDelete } = promo;
    return (
        <ContentTable
            content="promo"
            {...promo}
            data={data}
        />
    );
}