
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers/store";
import ContentTable from "@components/table/ContentTable";


export default function HeadlinePage() {
    const headline = useSelector((state: RootState) => state.headline)
    const { loading, error, headlines: data, showModal, refreshing, showModalDelete } = headline;
    return (
        <ContentTable
            content="headline"
            {...headline}
            data={data}
        />
    );
}