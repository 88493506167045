import React, { useCallback, useEffect } from 'react';
import { Navigate, RouteProps, useOutlet } from "react-router-dom";
import { ROUTES } from "@resources/routes-constants";
import './layout.sass'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/store/reducers/store';
import { MainSideBar } from '@components/navigations/MainSideBar';
import CustomAxios from '@utility/customAxios';
import { logout } from 'src/features/auth/authSlice';
import { addInterceptor } from 'src/features/interceptor/interceptorSlice';

export const PrivateLayout = ({ children, ...rest }: RouteProps) => {
  const outlet = useOutlet();
  const dispatch = useDispatch();

  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const accessToken = userInfo ? userInfo.accessToken : undefined;

  const [tokenInterceptor, setTokenInterceptor] = React.useState<number | undefined>(undefined);
  const [prevAccessToken, setPrevAccessToken] = React.useState<string | undefined>(undefined);

  
  const [logoutInterceptor, setLogoutInterceptor] = React.useState<number | undefined>(undefined);

  useEffect(() => {
    if (accessToken == undefined) return;
    if (accessToken == prevAccessToken) return;
    if (tokenInterceptor != undefined) {
      CustomAxios.interceptors.request.eject(tokenInterceptor);
    }
    const myInterceptor = CustomAxios.interceptors.request.use((config) => {
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
        console.log('Authorization header set:', config.headers.Authorization);
      }
      return config;
    }, error => {
      // Handle errors
      console.error('Request interceptor error:', error);
      return Promise.reject(error);
    });

    setPrevAccessToken(accessToken)
    setTokenInterceptor(myInterceptor)
    
    // Asign that tokeninterceptor is ready
    dispatch(addInterceptor('token'))
  }, [accessToken])

  useEffect(() => {
    if (logoutInterceptor != undefined) {
      CustomAxios.interceptors.request.eject(logoutInterceptor);
    }
    const interceptor = CustomAxios.interceptors.response.use(
      response => response,
      error => {
        // Handle errors
        if (error?.response?.status == 401) {
          dispatch(logout());
        }
        return Promise.reject(error);
      }
    );
    setLogoutInterceptor(interceptor)
    
    // Asign that logoutinterceptor is ready
    dispatch(addInterceptor('logout'))
  }, [accessToken])

  if (!accessToken) {
    return <Navigate to={ROUTES.LOGIN_ROUTE} />;
  }

  return (
    <div className='flex flex-row'>
      <MainSideBar />
      {outlet}
    </div>
  );
};