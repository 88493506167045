import React, { HTMLProps, ReactNode } from  'react';

interface ErrorProps extends HTMLProps<HTMLDivElement> {
    children: ReactNode;
  }
  
const ErrorComponent = ({ children, ...props }: ErrorProps) => {
    return (
      <div
        style={{ color: '#f23838', textAlign: 'center', margin: '0.5rem 0' }}
        {...props}
      >
        {children}
      </div>
    )
  }
  
  export default ErrorComponent