import { AsyncThunkPayloadCreatorReturnValue, createAsyncThunk } from "@reduxjs/toolkit"
import CustomAxios from "@utility/customAxios"
import { handleAxiosError } from "@utility/errorUtils";
import { LoginPayload, LoginResponse } from "./authTypes";

export const userLogin = createAsyncThunk<
    // Return type
    LoginResponse,
    // First argument type
    LoginPayload,
    // ThunkAPI type
    { rejectValue: string } 
>(
    'auth/login',
    async ({ email, password }, { rejectWithValue }) => {
        try {
            // configure header's Content-Type as JSON
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            const { data } = await CustomAxios.post<LoginResponse>(
                `/api/auth/login`,
                { email, password },
                config
            )
            return data
        } catch (error: unknown) {
            const errorMessage = handleAxiosError(error);
            return rejectWithValue(errorMessage)
        }
    }
)