import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';

import CarouselPrevImg from '@assets/venn/export/caraouselPrev.png';
import CarouselNextImg from '@assets/venn/export/caraouselNext.png';

import Showcase from '@assets/images/headline/headline.jpg';
import Showcase2 from '@assets/images/headline/headline2.jpg';

import EventAditChoday from '@assets/images/events/aditchoday flyer.jpg';
import EventNisa from '@assets/images/events/DJ Nisa fix.jpg';
import EventVinjaz from '@assets/images/events/vinjaz.jpg';

import UpcomingEventText from '@assets/venn/export/upcoming-event.png';

import Drink1 from '@assets/images/drinks/drink1.jpg';
import Drink2 from '@assets/images/drinks/drink2.jpg';

import StartFrom from '@assets/venn/export/20.00 - 00.00.png';

import { Carousel, Collapse, Dialog, Typography } from "@material-tailwind/react";
import { StickyNavBar } from '@components/navigations/StickyNavBar';

import Ambience1 from '@assets/images/venue/main hall/main hall.png';
import Ambience1Thumbnail from '@assets/images/venue/main hall/main hall thumbnail.png';

import Ambience2 from '@assets/images/venue/main hall seats/main hall seats.png';
import Ambience2Thumbnail from '@assets/images/venue/main hall seats/seat main hall thumbnail.png';

import Ambience3 from '@assets/images/venue/vip 13 & 14/vip 13 & 14.png';
import Ambience3Thumbnail from '@assets/images/venue/vip 13 & 14/vip 13 & 14 thumbnail.png';

import Ambience4 from '@assets/images/venue/vip 15 & 16/vip 15 & 16.png';
import Ambience4Thumbnail from '@assets/images/venue/vip 15 & 16/vip 15 & 16 thumbnail.png';

import Ambience5 from '@assets/images/venue/vip 17 & 18/vip 17 & 18.png';
import Ambience5Thumbnail from '@assets/images/venue/vip 17 & 18/vip 17 & 18 thumbnail.png';

import Ambience6 from '@assets/images/venue/vip 19 & 20/vip 19 & 20.png';
import Ambience6Thumbnail from '@assets/images/venue/vip 19 & 20/vip 19 & 20 thumbnail.png';

import Ambience7 from '@assets/images/venue/vvip 1/vvip 1.png';
import Ambience7Thumbnail from '@assets/images/venue/vvip 1/vvip 1 thumbnail.png';

import Ambience8 from '@assets/images/venue/vvip 2/vvip 2.png';
import Ambience8Thumbnail from '@assets/images/venue/vvip 2/vvip 2 thumbnail.png';

import VennLogo from '@assets/venn/export/Venn.png';

import Reservation from '@assets/venn/export/btn_reservation.png';

import { ROUTES } from '../../resources/routes-constants'
import WindowCarousel from '@components/carousels/WindowCarousel';
import { Interweave } from 'interweave';
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/store/reducers/store';
import { getHeadlineLandingPage } from 'src/features/headline/headlineAction';
import { getEventLandingPage } from 'src/features/event/eventAction';
import { getDrinkLandingPage } from 'src/features/drink/drinkAction';
import { getPromoLandingPage } from 'src/features/promo/promoAction';


const HomePage: React.FC = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch<AppDispatch>()



    const headline = useSelector((state: RootState) => state.headline)
    const { loading: loadingHeadline, headlines } = headline;

    const event = useSelector((state: RootState) => state.event)
    const { loading: loadingEvent, events } = event;

    const drink = useSelector((state: RootState) => state.drink)
    const { loading: loadingDrink, drinks } = drink;

    const [previewImage, setPreviewImage] = useState<string | undefined>(undefined);

    useEffect(() => {
        dispatch(getHeadlineLandingPage())
        dispatch(getEventLandingPage())
        dispatch(getDrinkLandingPage())
    }, [])

    const caraouselImgsDrinks = [
        {
            src: Drink1,
            alt: "drink 1"
        },
        {
            src: Drink2,
            alt: "drink 2"
        },
    ];

    const [currentEvent, setCurrentEvent] = useState(0);
    const mainContainer = 'mx-auto max-w-screen-lg my-6 lg:my-24 md:my-12 content__headline';

    const caraouselImgsAmbience = [
        {
            src: Ambience1,
            description: 'Maximum Capacity 500pax',
            thumbnail: Ambience1Thumbnail
        },
        {
            src: Ambience2,
            description: 'Maximum Capacity for each sofa is 8 pax',
            thumbnail: Ambience2Thumbnail
        },
        {
            src: Ambience3,
            description: 'Maximum Capacity for each sofa is 10 pax',
            thumbnail: Ambience3Thumbnail
        },
        {
            src: Ambience4,
            description: 'Maximum Capacity for each sofa is 10 pax',
            thumbnail: Ambience4Thumbnail
        },
        {
            src: Ambience5,
            description: 'Maximum Capacity for each sofa is 8 pax',
            thumbnail: Ambience5Thumbnail
        },
        {
            src: Ambience6,
            description: 'Maximum Capacity for each sofa is 12 pax',
            thumbnail: Ambience6Thumbnail
        },
        {
            src: Ambience7,
            description: 'Maximum Capacity 15pax',
            thumbnail: Ambience7Thumbnail
        },
        {
            src: Ambience8,
            description: 'Maximum Capacity 15pax',
            thumbnail: Ambience8Thumbnail
        },
    ];

    const [ambience, setAmbience] = useState(caraouselImgsAmbience[0])
    const [animate, setAnimate] = useState(false);

    React.useEffect(() => {
        // Trigger animation when imageSrc changes
        const timeout = setTimeout(() => setAnimate(false), 1000); // Adjust time to match the animation duration
        return () => clearTimeout(timeout);
    }, [ambience]);


    const navigateToReservationPage = () => {
        navigate(ROUTES.RESERVATIONPAGE_ROUTE)
    }

    const filteredEvents = events.map((value) => {
        return {
            src: value.imagePath,
            alt: value.alternativeDesc,
            description: value.description,
            date: moment(value.eventDate)
        }
    }).filter((event) => {
        const now = moment().startOf('day');
        const eventDate = moment(event.date).startOf('day');
        return eventDate.isSameOrAfter(now);
    });

    return (
        <div className="w-full bg-black bg-vintage">
            <StickyNavBar />
            <div className='mx-auto content__headline' id="page_home">
                <Carousel autoplay={true} transition={{ duration: 2 }} loop={true} className="rounded-xl" style={{ height: '50vw' }}
                    prevArrow={() => null}
                    nextArrow={() => null}
                >
                    {
                        headlines.map((value, index) => {
                            return <img
                                key={index}
                                src={value.imagePath}
                                alt={value.alternativeDesc}
                                // {...value}
                                className="w-full object-cover"
                                style={{
                                    height: '50vw',
                                }}
                            />
                        })
                    }
                </Carousel>
            </div>

            <div className={`${mainContainer}`} id="page_upcomingevent">
                <Carousel
                    transition={{ duration: 2 }} loop={true} className="rounded-xl w-1/2"
                    style={{

                    }}
                    prevArrow={({ handlePrev, activeIndex, loop }) => (
                        <button>
                            <img
                                className="!absolute top-2/4 left-0 -translate-x-4"
                                onClick={() => {
                                    handlePrev();
                                    let prevIndex = activeIndex - 1;
                                    if (activeIndex == 0) prevIndex = filteredEvents.length - 1;
                                    setCurrentEvent(prevIndex);
                                }}
                                src={CarouselPrevImg}
                                alt="Previous"
                            />
                        </button>
                    )}
                    nextArrow={({ handleNext, activeIndex }) => (
                        <button>
                            <img
                                className="!absolute top-2/4 right-0 translate-x-4"
                                onClick={() => {
                                    handleNext();
                                    let nextIndex = activeIndex + 1;
                                    if (activeIndex == filteredEvents.length - 1) nextIndex = 0;
                                    setCurrentEvent(nextIndex);
                                }}
                                src={CarouselNextImg}
                                alt="Previous"
                            />
                        </button>
                    )}
                >
                    {
                        filteredEvents.map((value, index) => {
                            return <LazyLoadImage
                                key={index}
                                src={value.src}
                                alt={value.alt}
                                about={value.date.format('DD MMM YYYY')}
                                className="h-full w-full object-cover"
                                onClick={() => { setPreviewImage(value.src) }}
                            />
                        })
                    }
                </Carousel>
                <div className='flex flex-col justify-start items-center w-1/2 text-white my-0 sm:my-10'>
                    <button className="w-3/6 hover:opacity-90">
                        <img onClick={navigateToReservationPage} src={Reservation} />
                    </button>
                    <img className="w-3/6" src={UpcomingEventText} />
                    <span className='mx-4 md:mx-8 lg:mx-20 my-4 py-1 sm:py-2 mt-4'>
                        {
                            filteredEvents.map((value, index) => {
                                return <Collapse key={index} animate={
                                    {
                                        mount: { scale: 1 },
                                        unmount: { scale: 0 },
                                    }
                                } open={currentEvent == index}>
                                    <Interweave className='text-md md:text-xl lg:text-2xl' color="white" content={value.description} />
                                </Collapse>
                            })
                        }
                    </span>
                </div>
            </div>
            <div className={`${mainContainer}`} id="page_promo">
                <Carousel autoplay={true} transition={{ duration: 2 }} loop={true} className="rounded-xl"
                    prevArrow={() => null}
                    nextArrow={() => null}
                >
                    {
                        drinks.map((value, index) => {
                            const prop = {
                                src: value.imagePath,
                                alt: value.alternativeDesc
                            }
                            return <LazyLoadImage
                                key={index}
                                src={prop.src}
                                alt={prop.alt}
                                className="h-full w-full object-cover"
                            />
                        })
                    }
                </Carousel>
            </div>

            <div className={`${mainContainer} flex flex-col justify-center items-center`}>
                <WindowCarousel />
                <img className="my-12" style={{ width: '30%', maxWidth: '300px' }} src={StartFrom} />
            </div>

            <div className='flex items-center flex-col bg-theme' id="page_ambience">
                <div className={`${mainContainer} flex-col py:12 lg:py-16`}>
                    <div className='relative text-center flex flex-col justify-center items-center'>
                        <LazyLoadImage
                            className={(animate ? 'fade-in' : '') + " mb-6"} src={ambience.src}
                            style={{ filter: 'saturate(-67)' }}
                        />
                        <Typography color="white" className={'font-bold text-xl sm:text-2xl md:text-3xl' + (animate ? ' fade-in' : '')}>
                            {ambience.description}
                        </Typography>
                    </div>
                    <div className='relative w-full py-24'
                    >
                        <div className='flex overflow-x-scroll' id="carousel-multi-image">
                            {caraouselImgsAmbience.map((value, index) => {
                                return (
                                    <button key={index} onClick={() => {
                                        setAmbience(value)
                                        setAnimate(true)
                                    }}>
                                        <LazyLoadImage
                                            key={index}
                                            className={"slider " + (index != 0 ? 'ml-5' : '')}
                                            id={"test" + index}
                                            src={value.src}
                                            alt={value.description}
                                            style={{ maxHeight: 300, maxWidth: 300, filter: 'saturate(0.24)' }}
                                        />
                                        {/* <img className={"slider " + (index != 0 ? 'ml-5' : '')} id={"test" + index} src={value.src} alt="movie" style={{ maxHeight: 300, maxWidth: 300, filter: 'saturate(0.24)' }} /> */}
                                    </button>
                                );
                            })}
                        </div>
                        <button>
                            <img
                                className="!absolute w-16 lg:w-32 shadow-lg top-2/4 left-0 -translate-x-4 -translate-y-1/2"
                                onClick={() => {
                                    const carousel = document.getElementById('carousel-multi-image');
                                    carousel?.scrollBy({
                                        left: -930, top: 0, behavior: 'smooth'
                                    });
                                }}
                                src={CarouselPrevImg}
                                alt="Previous"
                            />
                        </button>
                        <button>
                            <img
                                className="!absolute w-16 lg:w-32 shadow-lg top-2/4 right-0 translate-x-4 -translate-y-1/2"
                                onClick={() => {
                                    const carousel = document.getElementById('carousel-multi-image');
                                    carousel?.scrollBy({
                                        left: 930, top: 0, behavior: 'smooth'
                                    });

                                }}
                                src={CarouselNextImg}
                                alt="Previous"
                            />
                        </button>
                    </div>
                    <div className='mx-3 sm:mx-6 lg:mx-12 xl:mx-0' id="page_contactus">
                        <div className='flex'>
                            <div className='w-5/12 sm:w-1/2'>
                                <iframe
                                    className='w-full h-60 lg:h-96'
                                    style={{ border: 0 }}
                                    loading="lazy"
                                    allowFullScreen={true}
                                    referrerPolicy="no-referrer-when-downgrade"
                                    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDyyBNwjSg8mnzrmCdHugFX0y_KGSTsqXU&q=Venn+Club+Bar,Indonesia+Makassar">
                                </iframe>
                            </div>
                            <div className='w-7/12 sm:w-1/2 flex flex-col items-end'>
                                <Typography color='white' className='text-md md:text-xl lg:text-3xl py-1 sm:py-2 mt-4'>
                                    opening hours
                                </Typography>
                                <Typography className='font-light text-gold text-xl sm:text-2xl md:text-3xl lg:text-5xl py-1 sm:py-2 md:py-4 shrink'>
                                    SUNDAY - MONDAY
                                </Typography>
                                <Typography className='font-light text-gold text-xl sm:text-2xl md:text-3xl lg:text-5xl'>
                                    20.00 - 04.00
                                </Typography>
                            </div>
                        </div>
                        <div className='flex justify-between'>
                            <Typography className='w-1/2 my-6 font-normal text-md md:text-lg lg:text-xl text-gold'>
                                Jalan Boulevard Jl. Metro Tj. Bunga No.Kav. 1579, Kec. Tamalate, Kota Makassar, Sulawesi Selatan
                            </Typography>
                            <div className='w-1/2 text-right my-2'>
                                <Typography className='font-thin text-gold text-lg md:text-xl lg:text-3xl'>
                                    ONLINE RESERVATION
                                </Typography>
                                <Typography className='font-bold text-gold text-xl sm:text-2xl md:text-3xl lg:text-5xl'>
                                    +6282267893388
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex justify-center pt-36 pb-11'>
                    <img
                        className='w-20'
                        src={VennLogo}
                    />
                </div>
            </div>
            <Dialog size="xs" open={previewImage ? true : false} handler={setPreviewImage}>
                <img src={previewImage} />
            </Dialog>
        </div >
    )
}

export default HomePage
