import React from 'react';
import { Link, Navigate, Outlet } from "react-router-dom";
import { ROUTES } from "@resources/routes-constants";

export const HomeLayout = () => {
  return (
    <div>
      <Outlet />
    </div>
  )
};
