import { AsyncThunkPayloadCreatorReturnValue, createAsyncThunk } from "@reduxjs/toolkit"
import CustomAxios from "@utility/customAxios"
import { handleAxiosError } from "@utility/errorUtils";
import { Drinks, InsertDrinkPayload, PutDrinkPayload, DeleteDrinkPayload } from "./drinkTypes";
import { ApiResponse } from "../Types";

export interface InsertDrinkType {
    ReturnType: ApiResponse;
    FirstArgumentType: InsertDrinkPayload;
    ThunkAPIType: { rejectValue: string };
}

export const insertDrink = createAsyncThunk<
    InsertDrinkType['ReturnType'],
    InsertDrinkType['FirstArgumentType'],
    InsertDrinkType['ThunkAPIType'] // { rejectValue: string }
>(
    'drink/insert',
    async (formData: FormData, { rejectWithValue }) => {
        try {
            const { data } = await CustomAxios.post<ApiResponse>(
                `/api/drink/store`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            )
            return data
        } catch (error: unknown) {
            const errorMessage = handleAxiosError(error);
            return rejectWithValue(errorMessage)
        }
    }
)

export interface PutDrinkType {
    ReturnType: ApiResponse;
    FirstArgumentType: PutDrinkPayload;
    ThunkAPIType: { rejectValue: string };
}

export const putDrink = createAsyncThunk<
    PutDrinkType['ReturnType'],
    PutDrinkType['FirstArgumentType'],
    PutDrinkType['ThunkAPIType'] // { rejectValue: string }
>(
    'drink/edit',
    async ({
        id,
        formData
    }, { rejectWithValue }) => {
        try {
            const { data } = await CustomAxios.post<ApiResponse>(
                `/api/drink/` + id,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            )
            return data
        } catch (error: unknown) {
            const errorMessage = handleAxiosError(error);
            return rejectWithValue(errorMessage)
        }
    }
)
export interface DeleteDrinkType {
    ReturnType: ApiResponse;
    FirstArgumentType: DeleteDrinkPayload;
    ThunkAPIType: { rejectValue: string };
}

export const deleteDrink = createAsyncThunk<
    DeleteDrinkType['ReturnType'],
    DeleteDrinkType['FirstArgumentType'],
    DeleteDrinkType['ThunkAPIType'] // { rejectValue: string }
>(
    'drink/delete',
    async ({
        id
    }, { rejectWithValue }) => {
        try {
            const { data } = await CustomAxios.delete<ApiResponse>(
                `/api/drink/` + id
            )
            return data
        } catch (error: unknown) {
            const errorMessage = handleAxiosError(error);
            return rejectWithValue(errorMessage)
        }
    }
)

export interface GetDrinkType {
    ReturnType: ApiResponse;
    FirstArgumentType: void;
    ThunkAPIType: { rejectValue: string };
}

export const getDrink = createAsyncThunk<
    GetDrinkType['ReturnType'],  // ApiResponse
    GetDrinkType['FirstArgumentType'], // void
    GetDrinkType['ThunkAPIType'] // { rejectValue: string }
>(
    'drink/get',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await CustomAxios.get<ApiResponse>(
                `/api/drink`
            )
            return data
        } catch (error: unknown) {
            const errorMessage = handleAxiosError(error);
            return rejectWithValue(errorMessage)
        }
    }
)

export const getDrinkLandingPage = createAsyncThunk<
    GetDrinkType['ReturnType'],  // ApiResponse
    GetDrinkType['FirstArgumentType'], // void
    GetDrinkType['ThunkAPIType'] // { rejectValue: string }
>(
    'drink/get/landingpage',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await CustomAxios.get<ApiResponse>(
                `/api/data/drink`
            )
            return data
        } catch (error: unknown) {
            const errorMessage = handleAxiosError(error);
            return rejectWithValue(errorMessage)
        }
    }
)