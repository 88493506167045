import { ThunkDispatch, UnknownAction, combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage
import data from './data'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import authSlice from 'src/features/auth/authSlice'
import headlineSlice from 'src/features/headline/headlineSlice'
import interceptorSlice from 'src/features/interceptor/interceptorSlice'
import drinkSlice from 'src/features/drink/drinkSlice'
import promoSlice from 'src/features/promo/promoSlice'
import eventSlice from 'src/features/event/eventSlice'

const rootReducer = combineReducers({
    data: data,
    user: authSlice,
    headline: headlineSlice,
    drink: drinkSlice,
    promo: promoSlice,
    event: eventSlice,
    interceptor: interceptorSlice
});

const persistedReducer = persistReducer(
    {
        key: 'root',
        storage,
        whitelist: ['data', 'user']
    },
    rootReducer
)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        })
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = ThunkDispatch<RootState, any, UnknownAction>

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const persistor = persistStore(store)
