import React, { Dispatch, SetStateAction, useEffect } from "react";
import {
    Input,
    Popover,
    PopoverHandler,
    PopoverContent,
} from "@material-tailwind/react";
import { format } from "date-fns";
import { DayButtonProps, DayPicker } from "react-day-picker";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";

interface PropType {
    dateValue: string | undefined,
    setDateValue: (date: Date | undefined) => void;
}

export default function DateInput({
    dateValue,
    setDateValue,
}: PropType) {
    const [date, setDate] = React.useState<Date>();

    useEffect(() => {
        console.log(dateValue)
        let d = undefined;
        if (dateValue) {
            d = new Date(dateValue)
        }

        setDate(d);
    }, [dateValue])

    return (
        <Popover placement="bottom">
            <PopoverHandler>
                <Input
                    label="Select a Date"
                    onChange={() => null}
                    value={date ? format(date, "PPPP") : ""}
                />
            </PopoverHandler>
            <PopoverContent className="z-[99999]">
                <DayPicker
                    mode="single"
                    onSelect={(date) => {
                        setDateValue(date);
                    }}
                    selected={date}
                    showOutsideDays
                    className="border-0"
                />
            </PopoverContent>
        </Popover>
    );
}
