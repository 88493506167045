import React, { useEffect } from 'react'

import CarouselPrevImg from '@assets/venn/export/caraouselPrev.png';
import CarouselNextImg from '@assets/venn/export/caraouselNext.png';

import Promo1 from '@assets/images/promos/tequila time.jpg';
import Promo2 from '@assets/images/promos/whiskey day.jpg';
import Promo3 from '@assets/images/promos/the unstop.jpg';
import { getPromoLandingPage } from 'src/features/promo/promoAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/store/reducers/store';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface CarouselImage {
    src: string,
    alt: string,
    positionindex: number,
    zindex: number
}
const WindowCarousel: React.FC = () => {
    const styleLeft = {
        maxWidth: 300,
        width: '38%',
        zIndex: 1,
        transform: `translate(-70%, 0%)`,
    }
    const styleRight = {
        maxWidth: 300,
        width: '38%',
        zIndex: 1,
        transform: `translate(70%, 0%)`,
    }
    const styleCenter = {
        maxWidth: 500,
        width: '50%',
        boxShadow: '0px 0px 120px black',
        zIndex: 2,
        transform: 'translate(0%, 0%)',
    }
    const defaultRotation = [
        styleLeft,
        styleCenter,
        styleRight
    ]

    const dispatch = useDispatch<AppDispatch>()

    const promo = useSelector((state: RootState) => state.promo)
    const { loading: loadinngPromo, promos } = promo;

    const [carouselImgsPromo, setCarouselImgsPromo] = React.useState<Array<CarouselImage>>([
        {
            src: Promo1,
            alt: "promo 1",
            positionindex: 0,
            zindex: 1
        },
        {
            src: Promo2,
            alt: "promo 2",
            positionindex: 1,
            zindex: 3
        },
        {
            src: Promo3,
            alt: "promo 3",
            positionindex: 2,
            zindex: 2
        },
    ])

    useEffect(() => {
        dispatch(getPromoLandingPage())
    }, [])

    useEffect(() => {

        // Step 1: Sort the array by priority (sudah di sort shughi)
        // const sortedPromos = [...promos].sort((a, b) => a.priority - b.priority);

        // Step 2: Select and rearrange the top 3 items
        const topThreePromos = [...promos].slice(0, 3);
        const rearrangedPromos = [
            topThreePromos[2], // Last item becomes the first
            topThreePromos[0], // First item becomes the second
            topThreePromos[1]  // Second item becomes the third
        ].filter(Boolean); // Filter out any falsy values (e.g., undefined)

        // Step 3: Map to the desired format with specific zindex values
        const zindexValues = [1, 3, 2]; // Define zindex values
        const carousel = rearrangedPromos.map((promo, index) => ({
            src: promo.imagePath,
            alt: promo.alternativeDesc,
            positionindex: index,
            zindex: zindexValues[index]
        }));

        // Step 4: Set the carousel images
        setCarouselImgsPromo(carousel);
    }, [promos])

    return (
        <div className='w-full relative flex justify-center items-center'>
            {
                carouselImgsPromo.map((value, index) => {
                    return <LazyLoadImage
                        key={index}
                        src={value.src}
                        alt={value.alt}
                        className="object-cover"
                        style={{
                            position: 'absolute',
                            transition: 'maxWidth 1s, width 1s, transform 1s',
                            ...defaultRotation[value.positionindex],
                            zIndex: value.positionindex == 1 ? 3 : value.zindex
                        }}
                    />
                })
            }
            {
                carouselImgsPromo?.length != 0 &&
                <LazyLoadImage
                    src={carouselImgsPromo[0].src}
                    style={{
                        maxWidth: 600,
                        width: '50%',
                    }}
                    className="h-full w-full object-cover opacity-0"
                />
            }
            <button>
                <img
                    className="!absolute w-16 lg:w-32 shadow-lg top-2/4 left-0 -translate-x-4 -translate-y-1/2 z-10"
                    onClick={() => {
                        setCarouselImgsPromo(prevState => {
                            const carousel = prevState.map((value) => {
                                return {
                                    ...value,
                                    positionindex: value.positionindex == carouselImgsPromo.length - 1 ? 0 : value.positionindex + 1,
                                    zindex: value.positionindex == carouselImgsPromo.length - 1 ? 1 : 2,
                                }
                            })
                            return carousel;
                        })
                    }}
                    src={CarouselPrevImg}
                    alt="Previous"
                />
            </button>
            <button>
                <img
                    className="!absolute w-16 lg:w-32 shadow-lg top-2/4 right-0 translate-x-4 -translate-y-1/2 z-10"
                    onClick={() => {
                        setCarouselImgsPromo(prevState => {
                            const carousel = prevState.map((value) => {
                                return {
                                    ...value,
                                    positionindex: value.positionindex == 0 ? carouselImgsPromo.length - 1 : value.positionindex - 1,
                                    zindex: value.positionindex == 0 ? 1 : 2,
                                }
                            })
                            return carousel;
                        })
                    }}
                    src={CarouselNextImg}
                    alt="Previous"
                />
            </button>
        </div>
    )
}

export default WindowCarousel