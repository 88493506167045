import React from 'react'
import { BrowserRouter as Router, Route, Routes, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import HomePage from './pages/HomePage'
import ReservationPage from './pages/ReservationPage'
import NotFoundPage from './pages/NotFoundPage'
import { ROUTES } from './resources/routes-constants'
import './styles/main.sass'
import { LoginPage } from './pages/Authentication/Login'
import DashboardPage from './pages/Admin/DashboardPage'
import { PrivateLayout } from '@components/authentication/Layout/PrivateLayout'
import { HomeLayout } from '@components/authentication/Layout/HomeLayout'
import HeadlinePage from './pages/Admin/WebsiteContent/HeadlinePage'
import DrinkPage from './pages/Admin/WebsiteContent/DrinkPage'
import PromoPage from './pages/Admin/WebsiteContent/PromoPage'
import EventPage from './pages/Admin/WebsiteContent/EventPage'

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<HomeLayout />}>
        <Route path="*" element={<NotFoundPage />} />
        <Route path={ROUTES.HOMEPAGE_ROUTE} element={<HomePage />} />
        <Route path={ROUTES.RESERVATIONPAGE_ROUTE} element={<ReservationPage />} />
        <Route path={ROUTES.LOGIN_ROUTE} element={<LoginPage />} />
      </Route>

      <Route path={ROUTES.ADMIN_ROUTE} element={<PrivateLayout />}>
        <Route path={ROUTES.DASHBOARD_ROUTE} element={<DashboardPage />} />
        <Route path={ROUTES.HEADLINE_ROUTE} element={<HeadlinePage />} />
        <Route path={ROUTES.DRINK_ROUTE} element={<DrinkPage />} />
        <Route path={ROUTES.PROMO_ROUTE} element={<PromoPage />} />
        <Route path={ROUTES.EVENT_ROUTE} element={<EventPage />} />
      </Route>
    </Route>
  )
);
