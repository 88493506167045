// features/headline/headlineSlice.js
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {Headline } from './headlineTypes';
import { deleteHeadline, getHeadline, getHeadlineLandingPage, insertHeadline, putHeadline } from './headlineAction';
import { ApiResponse } from '../Types';

interface HeadlineState {
    loading: boolean;
    headlines: Array<Headline> | []; // Adjust as needed
    error: string | null; // Ensure this is string or null
    success: boolean;
    showModal: boolean,
    refreshing: boolean,
    showModalDelete: boolean,
}

// initialize userToken from local storage
const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null

const initialState: HeadlineState = {
    loading: false,
    headlines: [], // or {} depending on your data structure
    error: null,
    success: false,
    showModal: false,
    refreshing: false,
    showModalDelete: false,
};

const headlineSlice = createSlice({
    name: 'headline',
    initialState,
    reducers: {
        getHeadlines: (state, { payload }) => {
            state.headlines = payload
        },
        actionToggleModalHeadline: (state) => {
            state.showModal = !state.showModal;
        },
        actionToggleModalHeadlineDelete: (state) => {
            state.showModalDelete = !state.showModalDelete;
        },
    },
    extraReducers: (builder) => {
        builder
            // Insert
            .addCase(insertHeadline.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(insertHeadline.fulfilled, (state, { payload }: PayloadAction<ApiResponse<Headline>>) => {
                state.loading = false;
                state.headlines = [...state.headlines];
                state.success = true; // Optionally handle success
                state.showModal = false;
                state.refreshing = true;
            })
            .addCase(insertHeadline.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload as string; // Type assertion if payload is string
                state.success = false; // Optionally handle failure
            })
            // PUT
            .addCase(putHeadline.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(putHeadline.fulfilled, (state, { payload }: PayloadAction<ApiResponse<Headline>>) => {
                state.loading = false;
                state.headlines = state.headlines.map(headline => 
                    headline.id === payload.data.id ? payload.data : headline
                );
                state.success = true; // Optionally handle success
                state.showModal = false;
                state.refreshing = true;
            })
            .addCase(putHeadline.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload as string; // Type assertion if payload is string
                state.success = false; // Optionally handle failure
            })
            // Delete
            .addCase(deleteHeadline.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteHeadline.fulfilled, (state, { payload }: PayloadAction<ApiResponse<Headline>>) => {
                state.loading = false;
                state.success = true; // Optionally handle success
                state.showModalDelete = false;
                state.refreshing = true;
            })
            .addCase(deleteHeadline.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload as string; // Type assertion if payload is string
                state.success = false; // Optionally handle failure
            })
            // Get
            .addCase(getHeadline.pending || getHeadlineLandingPage.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.refreshing = false;
            })
            .addCase(getHeadline.fulfilled || getHeadlineLandingPage.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.headlines = [...payload.data];
                state.success = true; // Optionally handle success
            })
            .addCase(getHeadline.rejected || getHeadlineLandingPage.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload as string; // Type assertion if payload is string
                state.success = false; // Optionally handle failure
            })
            // Get Landing Page
            .addCase(getHeadlineLandingPage.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.refreshing = false;
            })
            .addCase(getHeadlineLandingPage.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.headlines = [...payload.data];
                state.success = true; // Optionally handle success
            })
            .addCase(getHeadlineLandingPage.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload as string; // Type assertion if payload is string
                state.success = false; // Optionally handle failure
            });
    },


})

export const { actionToggleModalHeadline, actionToggleModalHeadlineDelete } = headlineSlice.actions
export default headlineSlice.reducer