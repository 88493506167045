// features/drink/drinkSlice.js
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { deleteDrink, getDrink, getDrinkLandingPage, insertDrink, putDrink } from './drinkAction';
import { ApiResponse } from '../Types';
import { Drink } from './drinkTypes';

interface DrinkState {
    loading: boolean;
    drinks: Array<Drink> | []; // Adjust as needed
    error: string | null; // Ensure this is string or null
    success: boolean;
    showModal: boolean,
    refreshing: boolean,
    showModalDelete: boolean,
}

// initialize userToken from local storage
const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null

const initialState: DrinkState = {
    loading: false,
    drinks: [], // or {} depending on your data structure
    error: null,
    success: false,
    showModal: false,
    refreshing: false,
    showModalDelete: false,
};

const drinkSlice = createSlice({
    name: 'drink',
    initialState,
    reducers: {
        getDrinks: (state, { payload }) => {
            state.drinks = payload
        },
        actionToggleModalDrink: (state) => {
            state.showModal = !state.showModal;
        },
        actionToggleModalDrinkDelete: (state) => {
            state.showModalDelete = !state.showModalDelete;
        },
    },
    extraReducers: (builder) => {
        builder
            // Insert
            .addCase(insertDrink.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(insertDrink.fulfilled, (state, { payload }: PayloadAction<ApiResponse<Drink>>) => {
                state.loading = false;
                state.drinks = [...state.drinks];
                state.success = true; // Optionally handle success
                state.showModal = false;
                state.refreshing = true;
            })
            .addCase(insertDrink.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload as string; // Type assertion if payload is string
                state.success = false; // Optionally handle failure
            })
            // PUT
            .addCase(putDrink.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(putDrink.fulfilled, (state, { payload }: PayloadAction<ApiResponse<Drink>>) => {
                state.loading = false;
                state.drinks = state.drinks.map(drink => 
                    drink.id === payload.data.id ? payload.data : drink
                );
                state.success = true; // Optionally handle success
                state.showModal = false;
                state.refreshing = true;
            })
            .addCase(putDrink.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload as string; // Type assertion if payload is string
                state.success = false; // Optionally handle failure
            })
            // Delete
            .addCase(deleteDrink.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteDrink.fulfilled, (state, { payload }: PayloadAction<ApiResponse<Drink>>) => {
                state.loading = false;
                state.success = true; // Optionally handle success
                state.showModalDelete = false;
                state.refreshing = true;
            })
            .addCase(deleteDrink.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload as string; // Type assertion if payload is string
                state.success = false; // Optionally handle failure
            })
            // Get
            .addCase(getDrink.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.refreshing = false;
            })
            .addCase(getDrink.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.drinks = [...payload.data];
                state.success = true; // Optionally handle success
            })
            .addCase(getDrink.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload as string; // Type assertion if payload is string
                state.success = false; // Optionally handle failure
            })
            // Get
            .addCase(getDrinkLandingPage.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.refreshing = false;
            })
            .addCase(getDrinkLandingPage.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.drinks = [...payload.data];
                state.success = true; // Optionally handle success
            })
            .addCase(getDrinkLandingPage.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload as string; // Type assertion if payload is string
                state.success = false; // Optionally handle failure
            });
    },


})

export const { actionToggleModalDrink, actionToggleModalDrinkDelete } = drinkSlice.actions
export default drinkSlice.reducer