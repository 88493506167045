import React from "react";
import { CardBody, Spinner, Typography } from "@material-tailwind/react";
import { ReactNode } from "react";

interface TableBodyHeader {
    TABLE_HEAD: Array<string>,
    children: ReactNode,
    loading: boolean,
}

export default function TableBody(props: TableBodyHeader) {
    const {
        TABLE_HEAD,
        children,
        loading,
    } = props;
    return <CardBody className="overflow-auto px-0">
        <table className="w-full min-w-full table-auto text-left">
            <thead>
                <tr>
                    {TABLE_HEAD.map((value) => (
                        <th
                            key={value}
                            className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                        >
                            <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal leading-none opacity-70"
                            >
                                {value}
                            </Typography>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {loading && <tr>
                    <td className={'p-4 border-b border-blue-gray-50'} colSpan={100}>
                        <div className="flex justify-center">
                            <Spinner /> Loading data... If this message persists, please refresh or check back later.
                        </div>
                    </td>
                </tr>
                }
                {children}
            </tbody>
        </table>
    </CardBody>
}