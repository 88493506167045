// import { useAuth } from '@components/authentication/Authentication';
import DateDisplay from '@components/DateDisplay';
import React from 'react'
import { useDispatch } from 'react-redux';
import { logout } from 'src/features/auth/authSlice';
const DashboardPage: React.FC = () => {
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(logout());
    };
    
    return (
        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <button onClick={handleLogout}>Logout</button>
            <h1 style={{ fontSize: '4em' }}>Hello world!</h1>
            <DateDisplay />
        </div>
    )
}

export default DashboardPage
