import { Typography } from '@material-tailwind/react'
import { ROUTES } from '@resources/routes-constants';
import React from 'react'
import { useNavigate } from 'react-router-dom';

const ReservationPage: React.FC = () => {
    const navigate = useNavigate();

    const navigateToHomePage = () => {
        navigate(ROUTES.HOMEPAGE_ROUTE)
    }
    return (
        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <iframe style={{
                width: '100vw',
                height: '100vh'
            }} src="https://www.erestaurants.co/modules/loyalty/dist/home/0?restaurant=ID_MA_R_VennClubMakassar" ></iframe>
            <button className='absolute top-10 left-10 lg:left-16' onClick={navigateToHomePage}>
                <Typography color="white" className={'py-3 px-6 font-bold text-xl sm:text-2xl md:text-3xl'}>
                    Back
                </Typography>
            </button>
        </div>
    )
}

export default ReservationPage