// features/event/eventSlice.js
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { deleteEvent, getEvent, getEventLandingPage, insertEvent, putEvent } from './eventAction';
import { ApiResponse } from '../Types';
import { Event } from './eventTypes';

interface EventState {
    loading: boolean;
    events: Array<Event> | []; // Adjust as needed
    error: string | null; // Ensure this is string or null
    success: boolean;
    showModal: boolean,
    refreshing: boolean,
    showModalDelete: boolean,
}

// initialize userToken from local storage
const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null

const initialState: EventState = {
    loading: false,
    events: [], // or {} depending on your data structure
    error: null,
    success: false,
    showModal: false,
    refreshing: false,
    showModalDelete: false,
};

const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        getEvents: (state, { payload }) => {
            state.events = payload
        },
        actionToggleModalEvent: (state) => {
            state.showModal = !state.showModal;
        },
        actionToggleModalEventDelete: (state) => {
            state.showModalDelete = !state.showModalDelete;
        },
    },
    extraReducers: (builder) => {
        builder
            // Insert
            .addCase(insertEvent.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(insertEvent.fulfilled, (state, { payload }: PayloadAction<ApiResponse<Event>>) => {
                state.loading = false;
                state.events = [...state.events];
                state.success = true; // Optionally handle success
                state.showModal = false;
                state.refreshing = true;
            })
            .addCase(insertEvent.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload as string; // Type assertion if payload is string
                state.success = false; // Optionally handle failure
            })
            // PUT
            .addCase(putEvent.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(putEvent.fulfilled, (state, { payload }: PayloadAction<ApiResponse<Event>>) => {
                state.loading = false;
                state.events = state.events.map(event => 
                    event.id === payload.data.id ? payload.data : event
                );
                state.success = true; // Optionally handle success
                state.showModal = false;
                state.refreshing = true;
            })
            .addCase(putEvent.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload as string; // Type assertion if payload is string
                state.success = false; // Optionally handle failure
            })
            // Delete
            .addCase(deleteEvent.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteEvent.fulfilled, (state, { payload }: PayloadAction<ApiResponse<Event>>) => {
                state.loading = false;
                state.success = true; // Optionally handle success
                state.showModalDelete = false;
                state.refreshing = true;
            })
            .addCase(deleteEvent.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload as string; // Type assertion if payload is string
                state.success = false; // Optionally handle failure
            })
            // Get
            .addCase(getEvent.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.refreshing = false;
            })
            .addCase(getEvent.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.events = [...payload.data];
                state.success = true; // Optionally handle success
            })
            .addCase(getEvent.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload as string; // Type assertion if payload is string
                state.success = false; // Optionally handle failure
            })
            // Get
            .addCase(getEventLandingPage.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.refreshing = false;
            })
            .addCase(getEventLandingPage.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.events = [...payload.data];
                state.success = true; // Optionally handle success
            })
            .addCase(getEventLandingPage.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload as string; // Type assertion if payload is string
                state.success = false; // Optionally handle failure
            });
    },


})

export const { actionToggleModalEvent, actionToggleModalEventDelete } = eventSlice.actions
export default eventSlice.reducer