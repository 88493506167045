
import React, { useEffect, useState } from "react";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import {
    Card,
    Typography,
    Button,
    Chip,
    Avatar,
    IconButton,
    Tooltip,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
} from "@material-tailwind/react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store/reducers/store";
import { deleteHeadline, getHeadline, insertHeadline, putHeadline } from "src/features/headline/headlineAction";
import { Headline, PutContentPayload } from "src/features/headline/headlineTypes";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import { ActionCreatorWithoutPayload, AsyncThunk } from "@reduxjs/toolkit";
import { ApiResponse, Content, ContentObject } from "src/features/Types";
import { deleteDrink, getDrink, insertDrink, putDrink } from "src/features/drink/drinkAction";
import AddContentForm, { FormType } from "@components/Form/AddContentForm";
import { actionToggleModalHeadline, actionToggleModalHeadlineDelete } from "src/features/headline/headlineSlice";
import { actionToggleModalDrink, actionToggleModalDrinkDelete } from "src/features/drink/drinkSlice";
import { deletePromo, getPromo, insertPromo, putPromo } from "src/features/promo/promoAction";
import { actionToggleModalPromo, actionToggleModalPromoDelete } from "src/features/promo/promoSlice";
import { deleteEvent, getEvent, insertEvent, putEvent } from "src/features/event/eventAction";
import { actionToggleModalEvent, actionToggleModalEventDelete } from "src/features/event/eventSlice";
import { Interweave } from "interweave";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";

interface PropType {
    content: Content;
    loading: boolean;
    error: string | null;
    data: Array<ContentObject>; // Use appropriate type based on your data structure
    showModal: boolean;
    refreshing: boolean;
    showModalDelete: boolean;
}

interface TableFunction {
    get: AsyncThunk<ApiResponse, void, { rejectValue: string }>
    insert: AsyncThunk<ApiResponse, FormData, { rejectValue: string }>
    put: AsyncThunk<ApiResponse, PutContentPayload, { rejectValue: string }>
    remove: AsyncThunk<ApiResponse, { id: number }, { rejectValue: string }>
    toggleAddModal: ActionCreatorWithoutPayload<string>
    toggleDeleteModal: ActionCreatorWithoutPayload<string>
}
type FunctionSettingType = {
    headline: TableFunction;
    drink: TableFunction;
    promo: TableFunction;
    event: TableFunction;
};

const FunctionSetting: FunctionSettingType = {
    'headline': {
        get: getHeadline,
        insert: insertHeadline,
        put: putHeadline,
        remove: deleteHeadline,
        toggleAddModal: actionToggleModalHeadline,
        toggleDeleteModal: actionToggleModalHeadlineDelete
    },
    'drink': {
        get: getDrink,
        insert: insertDrink,
        put: putDrink,
        remove: deleteDrink,
        toggleAddModal: actionToggleModalDrink,
        toggleDeleteModal: actionToggleModalDrinkDelete
    },
    'promo': {
        get: getPromo,
        insert: insertPromo,
        put: putPromo,
        remove: deletePromo,
        toggleAddModal: actionToggleModalPromo,
        toggleDeleteModal: actionToggleModalPromoDelete
    },
    'event': {
        get: getEvent,
        insert: insertEvent,
        put: putEvent,
        remove: deleteEvent,
        toggleAddModal: actionToggleModalEvent,
        toggleDeleteModal: actionToggleModalEventDelete
    }
}
export default function ContentTable(props: PropType) {
    const {
        content,
        data,
        loading,
        error,
        refreshing,
    } = props;
    const { showModal, showModalDelete } = useSelector((state: RootState) => state[content]);
    const actionContent = FunctionSetting[content as keyof FunctionSettingType];
    const dispatch = useDispatch<AppDispatch>();
    const { isInterceptorSet } = useSelector((state: RootState) => state.interceptor)

    const [previewImage, setPreviewImage] = useState<string | undefined>(undefined);
    const [modalType, setModalType] = useState<FormType>('add');
    const [selectedData, setSelectedData] = useState<Headline | undefined>(undefined)


    const TABLE_HEAD_EVENT: Array<string> = [content, 'Description', 'Alternative Description', 'Event Date', 'Last Updated', 'Status', '']
    const TABLE_HEAD: Array<string> = [content, 'Alternative Description', 'Priority', 'Last Updated', 'Status', '']
    const toggleModal = () => {
        dispatch(actionContent.toggleAddModal())
    }
    const openAddModal = () => {
        setModalType('add');
        toggleModal();
    }
    const openEditModal = (data: ContentObject) => {
        setModalType('edit');
        setSelectedData(data)
        toggleModal();
    }

    const toggleModalDelete = () => {
        dispatch(actionContent.toggleDeleteModal())
    }

    useEffect(() => {
        if (!isInterceptorSet) return;
        dispatch(actionContent.get());
    }, [dispatch, isInterceptorSet]);

    const refreshData = async () => {
        try {
            await dispatch(actionContent.get());
        } catch (error) {
            console.error(`Error fetching ${content}s:`, error);
        }
    };

    useEffect(() => {
        if (!refreshing) return;
        refreshData();
    }, [refreshing, dispatch]);

    return (
        <Card className="h-full w-full m-6">
            <div className="flex justify-end p-5">
                <Button className="rounded-full flex justify-center items-center" onClick={() => { refreshData() }}>
                    <FontAwesomeIcon icon={faArrowsRotate} size="2x" />Refresh
                </Button>
            </div>

            <TableHeader
                title={content}
                description={`Change the ${content} content here`}
                sideComponent={
                    <Button className="flex items-center gap-3" size="sm" onClick={openAddModal}>
                        <PlusIcon strokeWidth={2} className="h-4 w-4 capitalize" /> Add {content}
                    </Button>
                } />
            <TableBody
                TABLE_HEAD={content == 'event' ? TABLE_HEAD_EVENT : TABLE_HEAD}
                loading={loading}
            >
                {!loading && data.length == 0 &&
                    <tr>
                        <td className={'p-4 border-b border-blue-gray-50'} colSpan={100}>
                            <div className="flex justify-center">
                                Looks like there’s nothing here yet. Start by adding new items.
                            </div>
                        </td>
                    </tr>}
                {data.map(
                    (
                        value,
                        index,
                    ) => {
                        const {
                            id,
                            imagePath,
                            alternativeDesc,
                            description,
                            eventDate,
                            priority,
                            status,
                            createdAt,
                            updatedAt,
                        } = value;
                        const isLast = index === data.length - 1;
                        const classes = isLast
                            ? "p-4"
                            : "p-4 border-b border-blue-gray-50";
                        return (
                            <tr key={id}>
                                <td className={classes}>
                                    <div className="flex items-center gap-3 w-60 h-60">
                                        <Avatar
                                            src={imagePath}
                                            alt={alternativeDesc}
                                            size="xxl"
                                            className="cursor-zoom-in border border-blue-gray-50 bg-blue-gray-50/50 object-contain p-1 w-60 h-60"
                                            onClick={() => {
                                                setPreviewImage(imagePath)
                                            }}
                                        />
                                    </div>
                                </td>
                                {
                                    content == 'event' &&
                                    <td className={classes}>
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            <Interweave content={description} />
                                        </Typography>
                                    </td>
                                }
                                <td className={classes}>
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                    >
                                        {alternativeDesc}
                                    </Typography>
                                </td>
                                {
                                    content == 'event' &&
                                    <td className={classes}>
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            {eventDate ? moment(eventDate).format('DD MMM YYYY') : eventDate}
                                        </Typography>
                                    </td>
                                }
                                {
                                    content != 'event' &&
                                    <td className={classes}>
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            {priority}
                                        </Typography>
                                    </td>
                                }
                                <td className={classes}>
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                    >
                                        {moment(updatedAt).format('HH:mm - D MMM YYYY')}
                                    </Typography>
                                </td>
                                <td className={classes}>
                                    <div className="w-max">
                                        <Chip
                                            size="sm"
                                            variant="ghost"
                                            value={status}
                                            color={
                                                status === "Active"
                                                    ? "green"
                                                    : status === "Inactive"
                                                        ? "amber"
                                                        : "red"
                                            }
                                        />
                                    </div>
                                </td>
                                <td className={classes}>
                                    <Tooltip content={`Edit ${content}`}>
                                        <IconButton variant="text" onClick={() => {
                                            openEditModal(value)
                                        }}>
                                            <PencilIcon className="h-4 w-4" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip content={`Edit ${content}`}>
                                        <IconButton variant="text" onClick={() => {
                                            setSelectedData(value);
                                            toggleModalDelete()
                                        }}>
                                            <TrashIcon className="h-4 w-4" />
                                        </IconButton>
                                    </Tooltip>
                                </td>
                            </tr>
                        );
                    },
                )}
            </TableBody>

            <Dialog size="xs" open={previewImage ? true : false} handler={setPreviewImage}>
                <img src={previewImage} />
            </Dialog>
            <Dialog
                size="md"
                open={showModal}
                handler={toggleModal}
                className="bg-transparent shadow-none"
            >
                <AddContentForm
                    content={content}
                    defaultValue={selectedData}
                    modalType={modalType}
                    insertFunction={actionContent.insert}
                    putFunction={actionContent.put}
                    loading={loading}
                    error={error}
                />
            </Dialog>
            <Dialog
                size="sm"
                open={showModalDelete}
                handler={toggleModalDelete}
                className="bg-transparent shadow-none"
            >
                <Card>
                    <DialogHeader>Delete</DialogHeader>
                    <DialogBody>
                        Are you sure you want to delete?
                    </DialogBody>
                    <DialogFooter>
                        <Button
                            variant="text"
                            color="green"
                            onClick={toggleModalDelete}
                            disabled={loading}
                            className="mr-1"
                        >
                            <span>Cancel</span>
                        </Button>
                        <Button variant="gradient" color="red" loading={loading} onClick={() => {
                            if (selectedData) {
                                dispatch(actionContent.remove({ id: selectedData.id }))
                            }
                        }}>
                            <span>Confirm</span>
                        </Button>
                    </DialogFooter>
                </Card>

            </Dialog>
        </Card>
    );
}