
import React, { useEffect, useRef, useState } from "react";
import {
    Card,
    Typography,
    Button,
    CardBody,
    CardFooter,
    Tooltip,
    Input,
    Checkbox,
} from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store/reducers/store";
import { AsyncThunk } from "@reduxjs/toolkit";
import { Content, ContentObject } from "src/features/Types";
import DateInput from "./DateInput";
import moment from "moment";
import ErrorComponent from "@components/Error";
import TinyMCE, { TinyMCEHandle } from "./TinyMce";

export type FormType = 'add' | 'edit'

export interface PutPayload {
    id: number,
    formData: FormData
}
export interface ContentFormSetting<
    TInsertResponse,
    TPutResponse,
    TRejectValue
> {
    content: Content
    modalType: FormType,
    defaultValue?: Partial<ContentObject>
    insertFunction: AsyncThunk<TInsertResponse, FormData, { rejectValue: TRejectValue }>,
    putFunction: AsyncThunk<TPutResponse, PutPayload, { rejectValue: TRejectValue }>,
    loading: boolean,
    error: string | null;
}
type FormState = Partial<ContentObject>;

export default function AddContentForm<
    TInsertResponse,
    TPutResponse,
    TPayload,
    TRejectValue
>(props: ContentFormSetting<TInsertResponse, TPutResponse, TRejectValue>) {

    const dispatch = useDispatch<AppDispatch>();
    const {
        content,
        modalType,
        defaultValue,
        insertFunction,
        putFunction,
        loading,
        error
    } = props;

    const tinyMCERef = useRef<TinyMCEHandle>(null!);

    const [formObject, setFormObject] = useState<FormState>({
    });
    const [editedObject, setEditedObject] = useState<FormState>({
    });

    // When type edit, fill state with value
    useEffect(() => {
        if (modalType != "edit") return;
        setFormObject(defaultValue || {});
    }, [modalType, defaultValue])

    function addContent(value: any) {
        value.preventDefault();
        // // Create FormData object
        const formData = new FormData();
        const description = tinyMCERef?.current?.getContent();

        formData.append('image', formObject.file); // 'image' is the key and file is the File object
        formData.append('alternative_desc', formObject.alternativeDesc + '');
        if (content == 'event') {
            formData.append('description', description ?? '');
            formData.append('event_date', formObject.eventDate);
        } else {
            formData.append('priority', (formObject.priority ?? 1) + '');
        }
        // // Send the FormData object using fetch API
        dispatch(insertFunction(formData))
    }
    
    function editContent(value: any) {
        value.preventDefault();
        // // Create FormData object
        const formData = new FormData();

        if (editedObject.file) {
            formData.append('image', editedObject.file); // 'image' is the key and file is the File object
        }
        if (editedObject.alternativeDesc) {
            formData.append('alternative_desc', editedObject.alternativeDesc + '');
        }
        
        const description = tinyMCERef?.current?.getContent();
        if (content == 'event') {
            if (description) {
                formData.append('description', description ?? '');
            }
            if (editedObject.eventDate) {
                formData.append('event_date', editedObject.eventDate);
            }
        } else {
            if (editedObject.priority) {
                formData.append('priority', (editedObject.priority ?? 1) + '');
            }
        }


        if (editedObject.status) {
            formData.append('status', (editedObject.status == 'Active' ? 1 : 2) + '');
        }
        formData.append('_method', 'PUT');

        if (formObject?.id) {
            dispatch(putFunction({
                id: formObject?.id,
                formData: formData
            }))
        }

    }
    function updateContent(propName: string, value: any) {
        setFormObject(prev => {
            const state = { ...prev };
            state[propName] = value;
            return state;
        })
        setEditedObject(prev => {
            const state = { ...prev };
            state[propName] = value;
            return state;
        })
    }

    function ChangeImage() {
        // Single file
        const fileInput = document.getElementById('imageInput') as HTMLInputElement;
        if (!fileInput?.files || !fileInput?.files[0]) {
            console.log('No file selected');
            return;
        }
        const file = fileInput?.files[0]; // Get the first file (assuming single file upload)

        setFormObject(prev => {
            const state = { ...prev };
            state.file = file;
            state.alternativeDesc = file.name;
            return state;
        })
        setEditedObject(prev => {
            const state = { ...prev };
            state.file = file;
            state.alternativeDesc = file.name;
            return state;
        })

    }


    const modalSetting = {
        add: {
            title: `Create New ${content}`,
            desc: `Fill out the form below to add a new ${content} with image, description, priority, and status.`,
            button: `Create ${content}`,
            editStatus: false,
            submitFunction: addContent
        },
        edit: {
            title: `Edit ${content} Details`,
            desc: `Update the information for the selected ${content}, including the image, description, priority, and status.`,
            button: `Edit ${content}`,
            editStatus: true,
            submitFunction: editContent
        },
    }
    const currentModal = modalSetting[modalType];

    const { alternativeDesc = '', status, description, eventDate, priority } = formObject;

    return <Card className="mx-auto w-full max-w-[36rem]">
        <form onSubmit={currentModal.submitFunction}>
            <CardBody className="flex flex-col gap-4">
                <Typography variant="h4" color="blue-gray" className="capitalize">
                    {currentModal.title}
                </Typography>
                <Typography
                    className="mb-3 font-normal"
                    variant="paragraph"
                    color="gray"
                >
                    {currentModal.desc}
                </Typography>
                {error && <ErrorComponent>{error}</ErrorComponent>}
                <Tooltip content={`${content} Image`} className="z-[99999]">
                    <Typography className="-mb-2 cursor-pointer" variant="h6" color="black">
                        Image<span className="text-red-900">*</span>
                    </Typography>
                </Tooltip>
                <Input label="Image" type="file" size="lg" id={'imageInput'} onChange={ChangeImage} />
                <Tooltip content="This description will show up if image can't load." className="z-[99999]">
                    <Typography className="-mb-2 cursor-pointer" variant="h6" color="black">
                        Simple Description
                    </Typography>
                </Tooltip>
                <Input label="Alternative Description" value={alternativeDesc} size="lg" onChange={(e) => { updateContent('alternativeDesc', e?.target?.value) }} />
                {
                    content == "event" &&
                    <React.Fragment>
                        <TinyMCE
                            ref={tinyMCERef}
                            value={description}
                        />
                        <Tooltip content="Images will be displayed starting with the events happening today and moving forward." className="z-[99999]">
                            <Typography className="-mb-2 cursor-pointer" variant="h6" color="black">
                                Event Date<span className="text-red-900">*</span>
                            </Typography>
                        </Tooltip>
                        <DateInput
                            dateValue={eventDate}
                            setDateValue={(d) => {
                                console.log(d)
                                let date = undefined;
                                if (d) {
                                    date = moment(d).format('YYYY-MM-DD');
                                }
                                updateContent('eventDate', date)
                            }} />
                    </React.Fragment>
                }

                {
                    content != "event" &&
                    <>
                        <Tooltip content="Uploaded images will be displayed in ascending order (1, 2, 3, etc.)." className="z-[99999]">
                            <Typography className="-mb-2 cursor-pointer" variant="h6" color="black">
                                Priority<span className="text-red-900">*</span>
                            </Typography>
                        </Tooltip>
                        <Input label="Priority" type="number" size="lg" value={priority} min={1} onChange={(e) => { updateContent('priority', e?.target?.value) }} />
                    </>
                }
                {
                    currentModal.editStatus &&
                    <Checkbox label="Active" crossOrigin={'true'} checked={status == 'Active'} ripple={true} onChange={(e) => {
                        const changeTo = status == 'Active' ? 'Inactive' : "Active";
                        setFormObject(prev => {
                            const state = { ...prev };
                            state.status = changeTo;
                            return state;
                        })
                        setEditedObject(prev => {
                            const state = { ...prev };
                            state.status = changeTo;
                            return state;
                        })
                    }} />
                }
            </CardBody>
            <CardFooter className="pt-0">
                <Button variant="gradient" fullWidth type="submit" loading={loading} className="capitalize">
                    {currentModal.button}
                </Button>
            </CardFooter>
        </form>
    </Card>
}