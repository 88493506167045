import axios, { AxiosError } from 'axios';

interface ErrorResponse {
    message: string;
}

// Type guard to check if the error is an AxiosError
const isAxiosError = (error: unknown): error is AxiosError<ErrorResponse> => {
    return axios.isAxiosError(error);
};

export const handleAxiosError = (error: unknown): string => {
    if (isAxiosError(error)) {
        // Accessing the error message from AxiosError
        if (error.response && error.response.data) {
            return error.response.data.message || 'An error occurred';
        } else if (error.message) {
            return error.message;
        }
    } else if (error instanceof Error) {
        console.log(error);
        return error.message;
    }
    return 'An unknown error occurred';
};
